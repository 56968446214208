export function Discord () {
  return (
    <path d="M22,24L16.75,19L17.38,21H4.5A2.5,2.5 0 0,1 2,18.5V3.5A2.5,2.5 0 0,1 4.5,1H19.5A2.5,2.5 0 0,1 22,3.5V24M12,6.8C9.32,6.8 7.44,7.95 7.44,7.95C8.47,7.03 10.27,6.5 10.27,6.5L10.1,6.33C8.41,6.36 6.88,7.53 6.88,7.53C5.16,11.12 5.27,14.22 5.27,14.22C6.67,16.03 8.75,15.9 8.75,15.9L9.46,15C8.21,14.73 7.42,13.62 7.42,13.62C7.42,13.62 9.3,14.9 12,14.9C14.7,14.9 16.58,13.62 16.58,13.62C16.58,13.62 15.79,14.73 14.54,15L15.25,15.9C15.25,15.9 17.33,16.03 18.73,14.22C18.73,14.22 18.84,11.12 17.12,7.53C17.12,7.53 15.59,6.36 13.9,6.33L13.73,6.5C13.73,6.5 15.53,7.03 16.56,7.95C16.56,7.95 14.68,6.8 12,6.8M9.93,10.59C10.58,10.59 11.11,11.16 11.1,11.86C11.1,12.55 10.58,13.13 9.93,13.13C9.29,13.13 8.77,12.55 8.77,11.86C8.77,11.16 9.28,10.59 9.93,10.59M14.1,10.59C14.75,10.59 15.27,11.16 15.27,11.86C15.27,12.55 14.75,13.13 14.1,13.13C13.46,13.13 12.94,12.55 12.94,11.86C12.94,11.16 13.45,10.59 14.1,10.59Z" />
  )
}

export function Twitch () {
  return (
    <>
    
    <path
       style={{stroke: 'none', fillRule: 'nonzero', fill: 'black'}}
       d="M 2.914062 0.785156 L 1.515625 4.992188 L 1.515625 20.410156 L 7.105469 20.410156 L 7.105469 23.214844 L 9.902344 23.214844 L 12.699219 20.410156 L 16.894531 20.410156 L 22.484375 14.4375 L 22.484375 0.785156 Z M 21.085938 13.402344 L 17.171875 17.605469 L 12.214844 17.605469 L 9.203125 19.804688 L 9.203125 17.605469 L 4.308594 17.605469 L 4.308594 2.1875 L 21.085938 2.1875 Z M 21.085938 13.402344 "
       />
    <path
       style={{stroke: 'none', fillRule: 'nonzero', fill: 'black'}}
       d="M 11.300781 6.390625 L 12.699219 6.390625 L 12.699219 12 L 11.300781 12 Z M 11.300781 6.390625 "
       />
    <path
       style={{stroke: 'none', fillRule: 'nonzero', fill: 'black'}}
       d="M 15.496094 6.390625 L 16.894531 6.390625 L 16.894531 12 L 15.496094 12 Z M 15.496094 6.390625 "
       />
    </>
  )
}

export function Patreon () {
  return (
    <path d="M 15.386719 0.523438 C 10.621094 0.523438 6.746094 4.398438 6.746094 9.164062 C 6.746094 13.914062 10.621094 17.777344 15.386719 17.777344 C 20.136719 17.777344 24 13.914062 24 9.164062 C 24 4.398438 20.136719 0.523438 15.386719 0.523438 Z M 0.00390625 23.535156 L 4.222656 23.535156 L 4.222656 0.523438 L 0.00390625 0.523438 Z M 0.00390625 23.535156 "/>
  )
}